/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-10-20",
    versionChannel: "nightly",
    buildDate: "2023-10-20T00:20:58.027Z",
    commitHash: "8b5b135a2d08ed7b311fd0d081b32bd087e24d21",
};
